<template>
	<div class="content">
		<section class="head-section">
			<div class="container">
				<h1 class="title"
				data-aos="fade-right"
				data-aos-duration="1000">
			Lemberg Flowers – це садовий центр та квіткове господарство на Заході України із найбільшим вибором рослин</h1>
				<picture class="full-image"
				data-aos="opacity-animation" 
				data-aos-duration="2000">
					<source srcset="../assets/images/about-main-mob.jpg" type="image/jpg" media="(max-width: 440px)">
					<source srcset="../assets/images/about-main-mob.webp" type="image/webp" media="(max-width: 440px)"> 
					<source srcset="../assets/images/about-main.webp" type="image/webp">
					<img src="../assets/images/about-main.jpg" alt="Lember Flowers" :ref="setImagemRef">
				</picture>
				<picture class="left-side-img"
				data-aos="opacity-animation" 
				data-aos-duration="2000">
					<source srcset="../assets/images/about-main-2-mob.jpg" type="image/jpg" media="(max-width: 411px)">
					<source srcset="../assets/images/about-main-2-mob.webp" type="image/webp" media="(max-width: 411px)">
					<source srcset="../assets/images/about-main-2.webp" type="image/webp">
					<img src="../assets/images/about-main-2.jpg" alt="Flowers" :ref="setImagemRef">
				</picture>
				
				
				<div class="content">
					<p>Комплексне зовнішнє озеленення та фітодизайн інтер'єрів, а також продаж рослин у горщиках (гуртові ціни від 5000 грн.)</p>
				</div>
			</div>
		</section>
		<section class="statistics">
			<div class="container cols-2">
				<div class="conunts">
					<div class="count-item"><p>5000+</p><span>видів квітів</span></div>
					<div class="count-item"><p>100%</p><span>задоволених клієнтів</span></div>
					<div class="count-item"><p>3000+</p><span>озеленених об'єктів "під ключ"</span></div>
				</div>
				<div>
					<div class="flower-bg"></div>
					<h2>Вирощуємо локальні та нідерландські квіти й кімнатні рослини в горщиках.</h2>
					<div class="inner-container  cols-2">
						<p>Робимо квіткові композиції для балконів, лоджій, терас; озеленюємо офіси та інтер'єри закладів. Також наші роботи прикрашають центральні вулиці Львова.</p>
						<p>Доставляємо живці рослин (корінці, паростки, бокові гілки) для вегетативного розмноження по всій Україні.</p>
					</div>
					<h3>У садовому центрі можна придбати кімнатні рослини та квіти у горщиках.</h3>
				</div>
			</div>
			<div class="gallery-row">
				<div v-for="(img, index) in imgs"
				:key="index" class="pic"
				@click="() => showImg(index)">
					
					<picture>
						<source :srcset="typeof img === 'string' ? img : img.webpMob" type="image/jpg" media="(max-width: 440px)">
						<source :srcset="typeof img === 'string' ? img : img.thumbnailMob" type="image/webp" media="(max-width: 440px)">
						<source :srcset="typeof img === 'string' ? img : img.webp" type="image/webp">
						<img :src="typeof img === 'string' ? img : img.thumbnail"  alt="image gallery" :ref="setImagemRef">
					</picture>
				</div>
			</div>
			<vue-easy-lightbox
				:visible="visible"
				:imgs="imgs"
				:index="index"
				:moveDisabled = "moveDisabled"
				:scrollDisabled = "scrollDisabled"
				:swipeTolerance = "swipeTolerance"
				:escDisabled="escDisabled"
				:loop="loop"
				@hide="handleHide">
			</vue-easy-lightbox>
		</section>
		<section class="about-services">
			<div class="container cols-3 counts">
				<div v-for="service in services" :key="service" class="about-service-item">
						<div class="about-service-item-image">
							<router-link :to="service.button">
								<picture>
									<source :srcset="service.webp" type="image/webp">
									<img :src="service.src"  alt="image service" :ref="setImagemRef"/>
								</picture>
							</router-link>
						</div>
					
					
						<div class="about-service-item-title">
							<router-link :to="service.button">
								<h3 class="count" v-html="service.title"></h3>
							</router-link>
							</div>
					
						<div class="about-service-item-descr"><p>{{service.descr}}</p></div>
					
					<div class="about-service-item-btn">
						<router-link :to="service.button">+ більше інформації</router-link>
					</div>
				</div>
			</div>
		</section>
		<section>
			<div class="gallery">
				<div v-for="(img, index) in gallery"
				:key="index" :class="'pic ' +img.size"
				@click="() => showGalleryImg(index)">
					<picture>
						<source :srcset="typeof img === 'string' ? img : img.webpMob" type="image/jpg" media="(max-width: 440px)">
						<source :srcset="typeof img === 'string' ? img : img.thumbnailMob" type="image/webp" media="(max-width: 440px)">
						<source :srcset="typeof img === 'string' ? img : img.webp" type="image/webp">
						<img :src="typeof img === 'string' ? img : img.thumbnail" alt="image flower from Lember Flowers" :ref="setImagemRef">
					</picture>
				</div>
				<vue-easy-lightbox
				:visible="visibleImg"
				:imgs="gallery"
				:index="indexGallery"
				:moveDisabled = "moveDisabled"
				:scrollDisabled = "scrollDisabled"
				:swipeTolerance = "swipeTolerance"
				:escDisabled="escDisabled"
				:loop="loop"
				@hide="handleGalleryHide">
					
				</vue-easy-lightbox>
			</div>
		</section>
		<section>
			<v-contacts/>
		</section>
	</div>
</template>

<style scoped lang="scss">
	@import "../assets/scss/components/about-page.scss";
</style>

<script>
import VueEasyLightbox from 'vue-easy-lightbox' 
import Contacts from '@/components/Contacts.vue'

import { ref, onMounted, onBeforeUpdate } from 'vue'


export default {
	setup() {
      let imageRefs = ref([])
      const setImagemRef = el => {
      if (el) {
        imageRefs.value.push(el)
      }
    }
     onMounted(() => {
       imageRefs.value.forEach(function(entry) {
			entry.width = entry.clientWidth
			entry.height = entry.clientHeight
		});
      })
    onBeforeUpdate(() => {
      imageRefs.value = []

    })
    return {
      setImagemRef
    }
  },
	components: {
		VueEasyLightbox,
		'v-contacts': Contacts
	},
	data(){
		return {
			visible: false,
			index: 0,
			visibleImg: false,
			indexGallery: 0,
			moveDisabled:true,
			scrollDisabled : true,
			swipeTolerance : 50,
			escDisabled : true,
			loop: true,
			imgs: [
				{
					src: require('../assets/images/about-gallery-1/image-1.jpg'),
					thumbnailMob: require('../assets/images/about-gallery-1/small-1-mob.jpg'),
					thumbnail: require('../assets/images/about-gallery-1/small-1.jpg') 
				},
				{
					src: require('../assets/images/about-gallery-1/image-2.jpg'),
					thumbnailMob: require('../assets/images/about-gallery-1/small-2-mob.jpg'),
					thumbnail: require('../assets/images/about-gallery-1/small-2.jpg') 
				},
				{
					src: require('../assets/images/about-gallery-1/image-3.jpg'),
					thumbnailMob: require('../assets/images/about-gallery-1/small-3-mob.jpg'),
					thumbnail: require('../assets/images/about-gallery-1/small-3.jpg') 
				},
				{
					src: require('../assets/images/about-gallery-1/image-4.jpg'),
					thumbnailMob: require('../assets/images/about-gallery-1/small-4-mob.jpg'),
					thumbnail: require('../assets/images/about-gallery-1/small-4.jpg') 
				},
			],
			services:[
				{
					src: require('../assets/images/service-about-1.jpg'),
					webp: require('../assets/images/service-about-1.webp'),
					title: 'ОЗЕЛЕНЕННЯ<br>ОФІСІВ',
					descr: 'Внутрішнє озеленення офісних приміщень, продумане до деталей фітодизайнером',
					button: '/greening-of-offices'
				},
				{
					src: require('../assets/images/service-about-2.jpg'),
					webp: require('../assets/images/service-about-2.webp'),
					title: 'БАЛКОННЕ<br>ОЗЕЛЕНЕННЯ',
					descr: 'Квіткові композиції для балконів, терас готелів, ресторанів і приватних будинків: понад 300 варіантів оформлення',
					button: '/balcony-gardening'
				},
				{
					src: require('../assets/images/service-about-3.jpg'),
					webp: require('../assets/images/service-about-3.webp'),
					title: 'ЛАНДШАФТНИЙ<br>ДИЗАЙН',
					descr: 'Озеленення прибудинкової чи комерційної території, висадка дерев у саду: проєктування та реалізація',
					button: '/landscape-design'
				}
			],
			gallery: [
				{
					src: require('../assets/images/about-gallery-2/image-1.jpg'),
					webp: require('../assets/images/about-gallery-2/image-thumb-1.webp'),
					thumbnail: require('../assets/images/about-gallery-2/image-thumb-1.jpg'),
					size: 'large' 
				},
				/*{
					src: require('../assets/images/about-gallery-2/image-2.jpg'),
					webp: require('../assets/images/about-gallery-2/image-thumb-2.webp'),
					thumbnail: require('../assets/images/about-gallery-2/image-thumb-2.jpg'),
					size: '' 
				},*/
				{
					src: require('../assets/images/about-gallery-2/image-3.jpg'),
					webp: require('../assets/images/about-gallery-2/image-thumb-3.webp'),
					thumbnail: require('../assets/images/about-gallery-2/image-thumb-3.jpg'),
					size: '' 
				},
				{
					src: require('../assets/images/about-gallery-2/image-4.jpg'),
					webp: require('../assets/images/about-gallery-2/image-thumb-4.webp'),
					thumbnail: require('../assets/images/about-gallery-2/image-thumb-4.jpg'),
					size: '' 
				},
				{
					src: require('../assets/images/about-gallery-2/image-5.jpg'),
					webp: require('../assets/images/about-gallery-2/image-thumb-5.webp'),
					thumbnail: require('../assets/images/about-gallery-2/image-thumb-5.jpg'),
					size: ''
				},
				/*{
					src: require('../assets/images/about-gallery-2/image-6.jpg'),
					webp: require('../assets/images/about-gallery-2/image-thumb-6.webp'),
					thumbnail: require('../assets/images/about-gallery-2/image-thumb-6.jpg'),
					size: ''
				},*/
				{
					src: require('../assets/images/about-gallery-2/image-7.jpg'),
					webp: require('../assets/images/about-gallery-2/image-thumb-7.webp'),
					thumbnail: require('../assets/images/about-gallery-2/image-thumb-7.jpg'),
					size: 'large'
				},
				{
					src: require('../assets/images/about-gallery-2/image-8.jpg'),
					webp: require('../assets/images/about-gallery-2/image-thumb-8.webp'),
					thumbnail: require('../assets/images/about-gallery-2/image-thumb-8.jpg'),
					size: '' 
				},
				{
					src: require('../assets/images/about-gallery-2/image-9.jpg'),
					webp: require('../assets/images/about-gallery-2/image-thumb-9.webp'),
					thumbnail: require('../assets/images/about-gallery-2/image-thumb-9.jpg'),
					size: 'small'
				},
				{
					src: require('../assets/images/about-gallery-2/image-10.jpg'),
					webp: require('../assets/images/about-gallery-2/image-thumb-10.webp'),
					thumbnail: require('../assets/images/about-gallery-2/image-thumb-10.jpg'),
					size: 'small'
				},
				{
					src: require('../assets/images/about-gallery-2/image-11.jpg'),
					webp: require('../assets/images/about-gallery-2/image-thumb-11.webp'),
					thumbnail: require('../assets/images/about-gallery-2/image-thumb-11.jpg'),
					size: 'small'
				},
				{
					src: require('../assets/images/about-gallery-2/image-12.jpg'),
					webp: require('../assets/images/about-gallery-2/image-thumb-12.webp'),
					thumbnail: require('../assets/images/about-gallery-2/image-thumb-12.jpg'),
					size: 'small' 
				},
				{
					src: require('../assets/images/about-gallery-2/image-13.jpg'),
					webp: require('../assets/images/about-gallery-2/image-thumb-13.webp'),
					thumbnail: require('../assets/images/about-gallery-2/image-thumb-13.jpg'),
					size: 'small'
				},
				{
					src: require('../assets/images/about-gallery-2/image-14.jpg'),
					webp: require('../assets/images/about-gallery-2/image-thumb-14.webp'),
					thumbnail: require('../assets/images/about-gallery-2/image-thumb-14.jpg'),
					size: 'large'
				},
				{
					src: require('../assets/images/about-gallery-2/image-15.jpg'),
					webp: require('../assets/images/about-gallery-2/image-thumb-15.webp'),
					thumbnail: require('../assets/images/about-gallery-2/image-thumb-15.jpg'),
					size: ''
				},
				{
					src: require('../assets/images/about-gallery-2/image-16.jpg'),
					webp: require('../assets/images/about-gallery-2/image-thumb-16.webp'),
					thumbnail: require('../assets/images/about-gallery-2/image-thumb-16.jpg'),
					size: '' 
				},
					{
					src: require('../assets/images/about-gallery-2/image-17.jpg'),
					webp: require('../assets/images/about-gallery-2/image-thumb-17.webp'),
					thumbnail: require('../assets/images/about-gallery-2/image-thumb-17.jpg'),
					size: '' 
				},
				{
					src: require('../assets/images/about-gallery-2/image-18.jpg'),
					webp: require('../assets/images/about-gallery-2/image-thumb-18.webp'),
					thumbnail: require('../assets/images/about-gallery-2/image-thumb-18.jpg'),
					size: 'large'
				},
				{
					src: require('../assets/images/about-gallery-2/image-19.jpg'),
					webp: require('../assets/images/about-gallery-2/image-thumb-19.webp'),
					thumbnail: require('../assets/images/about-gallery-2/image-thumb-19.jpg'),
					size: 'small'
				},
				{
					src: require('../assets/images/about-gallery-2/image-20.jpg'),
					webp: require('../assets/images/about-gallery-2/image-thumb-20.webp'),
					thumbnail: require('../assets/images/about-gallery-2/image-thumb-20.jpg'),
					size: 'small' 
				},
					{
					src: require('../assets/images/about-gallery-2/image-21.jpg'),
					webp: require('../assets/images/about-gallery-2/image-thumb-21.webp'),
					thumbnail: require('../assets/images/about-gallery-2/image-thumb-21.jpg'),
					size: 'medium' 
				},
				{
					src: require('../assets/images/about-gallery-2/image-22.jpg'),
					webp: require('../assets/images/about-gallery-2/image-thumb-22.webp'),
					thumbnail: require('../assets/images/about-gallery-2/image-thumb-22.jpg'),
					size: 'not-grow' 
				},
				{
					src: require('../assets/images/about-gallery-2/image-23.jpg'),
					webp: require('../assets/images/about-gallery-2/image-thumb-23.webp'),
					thumbnail: require('../assets/images/about-gallery-2/image-thumb-23.jpg'),
					size: 'not-grow' 
				},
			],
		}
	},
	methods: {
		showImg(index) {
			this.index = index
			this.visible = true
		},
		handleHide() {
			this.visible = false
		},
		showGalleryImg(index) {
			this.indexGallery = index
			this.visibleImg = true
		},
		handleGalleryHide() {
			this.visibleImg = false
		}
	}
}
</script>